<template>
  <div class="row">

  </div>
</template>
<script>

export default {
  components: {

  },
  data() {
    return {}
  },
  computed: {

  },
  methods: {

  },
  mounted() {

  },
  beforeDestroy() {

  }
};
</script>
<style></style>
